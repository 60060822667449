import React, { useEffect, useRef } from 'react';
import { FaTruck, FaShip, FaMotorcycle, FaBus, FaCarAlt, FaTaxi, FaAmbulance, FaShuttleVan } from 'react-icons/fa';
import { RiMotorbikeFill, RiShip2Fill } from 'react-icons/ri';
import { GiCargoShip, GiShipBow } from 'react-icons/gi';
import { TbShip } from 'react-icons/tb';
import { SiCodeship } from 'react-icons/si';


import './SolutionsPage.css'; // Estilo CSS para la página de soluciones

const SolutionsPage = () => {
    const terrestresRef = useRef(null);
    const maritimasRef = useRef(null);
  
    const animateCards = (cardsRef) => {
      const cards = cardsRef.current.querySelectorAll('.vehicle-card');
      cards.forEach(card => card.classList.add('animate'));
    };
  
    useEffect(() => {
      // Observador para las soluciones terrestres
      const terrestresObserver = new IntersectionObserver((entries) => {
        entries.forEach(entry => {
          if (entry.isIntersecting) {
            animateCards(terrestresRef);
          }
        });
      }, { threshold: 0.1 });
  
      // Observador para las soluciones marítimas
      const maritimasObserver = new IntersectionObserver((entries) => {
        entries.forEach(entry => {
          if (entry.isIntersecting) {
            animateCards(maritimasRef);
          }
        });
      }, { threshold: 0.1 });
  
      // Observamos los contenedores de las soluciones terrestres y marítimas
      if (terrestresRef.current) {
        terrestresObserver.observe(terrestresRef.current);
      }
      if (maritimasRef.current) {
        maritimasObserver.observe(maritimasRef.current);
      }
    }, []);
  
    return (
    <div className="solutions-container">
      <h2>Nos Solutions</h2>
      <div className="solutions-section">
        <h3>
          <FaTruck className="section-icon" />
          Solutions Terrestres
        </h3>
        <div className="vehicle-solutions" ref={terrestresRef}>
          <div className="vehicle-card">
            <FaTruck className="vehicle-icon" />
            <h3>Camions</h3>
            <p>
              Optimisez la logistique de votre flotte de camions et améliorez la gestion de vos livraisons.
            </p>
            <button className="cta-button">Obtenir plus de détails</button>
          </div>
          <div className="vehicle-card">
            <RiMotorbikeFill className="vehicle-icon" />
            <h3>Motos de Neige</h3>
            <p>
              Suivez vos motoneiges pour plus de sécurité et profitez de vos aventures en montagne en toute tranquillité.
            </p>
            <button className="cta-button">Obtenir plus de détails</button>
          </div>
          <div className="vehicle-card">
            <FaMotorcycle className="vehicle-icon" />
            <h3>Motos</h3>
            <p>
              Gardez le contrôle de votre moto et récupérez-la facilement en cas de vol ou de perte.
            </p>
            <button className="cta-button">Obtenir plus de détails</button>
          </div>
          <div className="vehicle-card">
            <FaShuttleVan className="vehicle-icon" />
            <h3>Vans et Véhicules Commerciaux</h3>
            <p>
              Suivi et gestion des flottes de vans et de véhicules commerciaux pour les livraisons et les services.
            </p>
            <button className="cta-button">Obtenir plus de détails</button>
          </div>
          <div className="vehicle-card">
            <FaBus className="vehicle-icon" />
            <h3>Véhicules de Transport en Commun</h3>
            <p>
              Suivi en temps réel des bus, trams ou taxis pour améliorer l'efficacité et fournir des informations aux passagers.
            </p>
            <button className="cta-button">Obtenir plus de détails</button>
          </div>
          <div className="vehicle-card">
            <FaCarAlt className="vehicle-icon" />
            <h3>Véhicules Personnels</h3>
            <p>
              Solutions pour les particuliers souhaitant suivre et protéger leurs voitures, motos ou vélos.
            </p>
            <button className="cta-button">Obtenir plus de détails</button>
          </div>
          <div className="vehicle-card">
            <FaTaxi className="vehicle-icon" />
            <h3>Véhicules de Location</h3>
            <p>
              Suivi et surveillance des flottes de voitures ou de motos de location pour améliorer la sécurité et la logistique.
            </p>
            <button className="cta-button">Obtenir plus de détails</button>
          </div>
          <div className="vehicle-card">
            <FaAmbulance className="vehicle-icon" />
            <h3>Véhicules d'Urgence</h3>
            <p>
              Suivi des ambulances, des véhicules de pompiers et de police pour améliorer les délais de réponse et la coordination.
            </p>
            <button className="cta-button">Obtenir plus de détails</button>
          </div>
        </div>
      </div>
      <div className="solutions-section">
        <h3>
          <FaShip className="section-icon" />
          Solutions Maritimes
        </h3>
        <div className="vehicle-solutions" ref={maritimasRef}>
          <div className="vehicle-card">
            <FaShip className="vehicle-icon" />
            <h3>Bateaux</h3>
            <p>
              Le suivi par satellite aide à protéger votre bateau et à le maintenir en sécurité en toutes circonstances, même en haute mer.
            </p>
            <button className="cta-button">Obtenir plus de détails</button>
          </div>
          <div className="vehicle-card">
            <FaMotorcycle className="vehicle-icon" />
            <h3>Motos Aquatiques</h3>
            <p>
              Protégez vos motos aquatiques et améliorez votre expérience nautique grâce au suivi par satellite.
            </p>
            <button className="cta-button">Obtenir plus de détails</button>
          </div>
          <div className="vehicle-card">
            <RiShip2Fill className="vehicle-icon" />
            <h3>Yachts et Embarcations de Loisirs</h3>
            <p>
              Suivi et sécurité pour les yachts et les embarcations de loisirs pour maintenir la tranquillité du propriétaire.
            </p>
            <button className="cta-button">Obtenir plus de détails</button>
          </div>
          <div className="vehicle-card">
            <GiCargoShip className="vehicle-icon" />
            <h3>Navires de Fret</h3>
            <p>
              Suivi et surveillance des flottes de navires de fret pour améliorer la logistique et optimiser les itinéraires.
            </p>
            <button className="cta-button">Obtenir plus de détails</button>
          </div>
          <div className="vehicle-card">
            <GiShipBow className="vehicle-icon" />
            <h3>Navires de Pêche</h3>
            <p>
              Suivi des navires de pêche pour garantir le respect des réglementations et améliorer l'efficacité de la pêche.
            </p>
            <button className="cta-button">Obtenir plus de détails</button>
          </div>
          <div className="vehicle-card">
            <TbShip className="vehicle-icon" />
            <h3>Navires de Croisière</h3>
            <p>
              Solutions de suivi pour les navires de croisière pour assurer la sécurité et le bien-être des passagers.
            </p>
            <button className="cta-button">Obtenir plus de détails</button>
          </div>
          <div className="vehicle-card">
            <SiCodeship className="vehicle-icon" />
            <h3>Embarcations de Sauvetage et de Sécurité Maritime</h3>
            <p>
              Suivi des embarcations de sauvetage et de surveillance pour les missions de sauvetage et de sécurité.
            </p>
            <button className="cta-button">Obtenir plus de détails</button>
          </div>
        </div>
        <div className="contact-section">
        <h3>Contact</h3>
        <p>
          Pour plus d'informations sur nos solutions de suivi par satellite, appelez-nous au +1-450-209-7144.
        </p>
        <p>
          Vous pouvez également nous envoyer un e-mail à{' '}
          <a href="mailto:info@navigogps.ca">info@navigogps.ca</a> pour toute question.
        </p>
        <p>
          Si vous préférez utiliser WhatsApp, il vous suffit de cliquer sur le lien suivant pour discuter avec nous:{' '}
          <a
            href="https://wa.me/+14502097144"
            target="_blank"
            rel="noopener noreferrer"
          >
            +1-450-209-7144
          </a>
        </p>
      </div>
      </div>
    </div>
  );
};

export default SolutionsPage;
