import React from 'react';
import { Box, Typography } from '@mui/material';
import './TextContainerComponent.css'; // Importa los estilos

const TextContainerComponent = ({ title, children }) => {
  return (
    <Box
      className="text-container-1" // Agrega la clase para los estilos del contenedor de texto
      mt={2}
      sx={{
        backgroundColor: '#f5f5f5',
        borderRadius: 2,
        border: '1px solid #333',
        boxShadow: '2px 2px 10px rgba(0, 0, 0, 0.2)',
      }}
    >
      <Typography variant="h3" align="center" sx={{ fontWeight: 'bold', mb: 2 }}>
        {title}
      </Typography>
      {children}
    </Box>
  );
};

export default TextContainerComponent;
