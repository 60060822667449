import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Header from './components/Header';
import Footer from './components/Footer';
import HomePage from './components/HomePage';
import ProductPage from './components/ProductPage';
import SolutionsPage from './components/SolutionsPage';
import AboutUsPage from './components/AboutUsPage';
import ContactUsPage from './components/ContactUsPage';

const App = () => {
    return (
        <Router>
            <Header />
            <Routes>
                <Route path="/" element={<HomePage />} />
                <Route path="/productos" element={<ProductPage />} />
                <Route path="/soluciones" element={<SolutionsPage />} />
                <Route path="/sobrenosotros" element={<AboutUsPage />} />
                <Route path="/contacto" element={<ContactUsPage />} />
            </Routes>
            <Footer />
        </Router>
    );
}

export default App;
