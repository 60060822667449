import React from 'react';
import { FaFacebook, FaInstagram, FaEnvelope } from 'react-icons/fa';
import { AiFillAndroid } from 'react-icons/ai'; // Importamos el icono de Android
import './Footer.css';

const Footer = () => {
  return (
    <footer className="footer-container">
      <div className="footer-content">
        <div className="contact-info">
          <p className="footer-text">© 2023 NAVIGO</p>
          <div className="email-info">
            <FaEnvelope className="icon" />
            <a href="mailto:info@navigogps.ca" className="email-link">
              info@navigogps.ca
            </a>
          </div>
        </div>
        <div className="social-icons">
          <a
            href="https://www.facebook.com/navigogps.ca"
            target="_blank"
            rel="noopener noreferrer"
          >
            <FaFacebook className="icon" />
          </a>
          <a
            href="https://instagram.com/navigogps.ca"
            target="_blank"
            rel="noopener noreferrer"
          >
            <FaInstagram className="icon" />
          </a>
          <a
            href="https://play.google.com/store/apps/details?id=org.navigo.manager"
            target="_blank"
            rel="noopener noreferrer"
          >
            <AiFillAndroid className="icon" /> {/* Icono de Android */}
          </a>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
