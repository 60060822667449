import React from 'react';

const ProductPage = () => {
    return (
        <div>
            <h2>Nuestros Productos</h2>
            {/* Aquí puedes agregar detalles sobre tus productos */}
        </div>
    );
}

export default ProductPage;
