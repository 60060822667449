import React from 'react';
import { Box, Typography } from '@mui/material';

const ServiceCard = ({ icon, title, description }) => {
  return (
    <Box
      sx={{
        textAlign: 'center',
        padding: '1rem',
        boxShadow: '0px 2px 4px rgba(0,0,0,0.1)',
        borderRadius: '0.5rem',
        margin: '1rem',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        transition: '0.3s',
        '&:hover': {
          boxShadow: '0px 4px 8px rgba(0,0,0,0.2)',
          transform: 'scale(1.05)',
        },
      }}
    >
      <div className="service-icon">{icon}</div>
      <Typography variant="h6" sx={{ mt: 1, fontWeight: 'bold' }}>
        {title}
      </Typography>
      <Typography variant="body2" sx={{ mt: 1 }}>
        {description}
      </Typography>
    </Box>
  );
};

export default ServiceCard;
