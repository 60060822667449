import React from 'react';
import SliderComponent from './SliderComponent';
import TextContainerComponent from './TextContainerComponent';
import MainButtonComponent from './MainButtonComponent';
import ServiceCard from './ServiceCard';
import { Box, Grid } from '@mui/material'; // Utilizamos Grid para un diseño más flexible
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import RoomIcon from '@mui/icons-material/Room';
import PowerSettingsNewIcon from '@mui/icons-material/PowerSettingsNew';
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import DriveEtaIcon from '@mui/icons-material/DriveEta';
import ReportProblemIcon from '@mui/icons-material/ReportProblem';
import SpeedIcon from '@mui/icons-material/Speed';
import BatteryAlertIcon from '@mui/icons-material/BatteryAlert';
import MapIcon from '@mui/icons-material/Map';
import TripOriginIcon from '@mui/icons-material/TripOrigin';
import EventNoteIcon from '@mui/icons-material/EventNote';
import ReplayIcon from '@mui/icons-material/Replay';


const HomePage = () => {
    const services = [
        {icon: <AccessTimeIcon fontSize="large" />, title: "Surveillance 24/7", description: "Nous vous offrons un support technique professionnel pour répondre à vos demandes et besoins."},
        {icon: <RoomIcon fontSize="large" />, title: "Localisation en Temps Réel", description: "Visualisez votre véhicule en temps réel, avec un rapport constant sur la plateforme."},
        {icon: <PowerSettingsNewIcon fontSize="large" />, title: "Arrêt à Distance", description: "En cas de perte ou de vol, vous pouvez arrêter le véhicule à distance instantanément."},
        {icon: <MailOutlineIcon fontSize="large" />, title: "Notifications et Alertes", description: "Recevez des notifications et des alertes en temps réel par e-mail et via notre application."},
        {icon: <DriveEtaIcon fontSize="large" />, title: "Alertes d'Allumage", description: "Nous vous informerons lorsque votre véhicule sera allumé, vous tenant ainsi informé de chaque mouvement."},
        {icon: <ReportProblemIcon fontSize="large" />, title: "Alertes de Mouvement et d'Arrêts", description: "Recevez des notifications lorsque votre véhicule est en mouvement ou à l'arrêt, vous permettant de garder un contrôle constant."},
        {icon: <SpeedIcon fontSize="large" />, title: "Alertes de Vitesse Excessive", description: "Recevez des alertes instantanées lorsque vos véhicules dépassent les limites de vitesse établies, favorisant la sécurité routière."},
        {icon: <BatteryAlertIcon fontSize="large" />, title: "Alertes de Déconnexion de la Batterie", description: "Nous vous informerons immédiatement si la batterie de votre véhicule est déconnectée, prévenant ainsi les problèmes techniques et les pertes de suivi."},
        {icon: <MapIcon fontSize="large" />, title: "Rapport d'Itinéraire", description: "Nous fournissons des rapports détaillés sur l'itinéraire de votre véhicule, vous aidant à optimiser vos trajets et vos temps."},
        {icon: <TripOriginIcon fontSize="large" />, title: "Rapport de Voyages et d'Arrêts", description: "Nos rapports couvrent tous les détails des voyages et des arrêts de vos véhicules, facilitant la gestion et la planification."},
        {icon: <EventNoteIcon fontSize="large" />, title: "Résumés Quotidiens", description: "Recevez des résumés quotidiens avec les détails les plus importants de la journée, vous aidant à maintenir une vision claire des opérations."},
        {icon: <ReplayIcon fontSize="large" />, title: "Reproduction d'Itinéraire", description: "Notre plateforme permet la reproduction de l'itinéraire de votre véhicule, facilitant l'analyse et l'amélioration de l'efficacité du trajet."},
    ];

    return (
        <div>
            <SliderComponent />
            <TextContainerComponent title="Nous vous proposons du suivi par satellite de base au contrôle complet de votre flotte.">
              <MainButtonComponent>
                Contactez-nous
              </MainButtonComponent>
            </TextContainerComponent>
            <TextContainerComponent title="Certains de nos services">
            <Grid container spacing={2} justifyContent="center">
              <Box 
                  display="flex" 
                  flexWrap="wrap" 
                  justifyContent="center" 
                  mt={2}
              >
                {services.map((service, index) => (
                  <Grid key={index} item xs={12} sm={6} md={4} lg={3}>
                  <ServiceCard {...service} />
                  </Grid>
                ))}
              </Box>
            </Grid>
            </TextContainerComponent>
        </div>
    );
}

export default HomePage;
