import React, { useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import HomeIcon from '@mui/icons-material/Home';
import InfoIcon from '@mui/icons-material/Info';
import ContactMailIcon from '@mui/icons-material/ContactMail';
import SolutionIcon from '@mui/icons-material/Extension';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import MenuIcon from '@mui/icons-material/Menu'; // Icono del menú
import logo from '../assets/images/logo.png';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer'; // Componente del menú desplegable

const Header = () => {
  const [drawerOpen, setDrawerOpen] = useState(false);

  const handleDrawerOpen = () => {
    setDrawerOpen(true);
  };

  const handleDrawerClose = () => {
    setDrawerOpen(false);
  };

  return (
    <div>
      <AppBar position="static" sx={{ background: '#2E3B55', transition: '0.3s' }}>
        <Toolbar sx={{ justifyContent: 'space-between' }}>
          <Box
            component="span"
            sx={{
              bgcolor: '#080f1c',
              borderRadius: '50%',
              p: 1,
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              mr: 2,
              boxShadow: '0 3px 5px 2px rgba(0, 255, 0, .3)',
              transition: '0.3s',
              '&:hover': {
                boxShadow: '0 6px 10px 4px rgba(0, 255, 0, .3)',
              },
            }}
          >
            <img src={logo} alt="Logo" height="100px" width="160px" />
          </Box>
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            {/* Icono del menú */}
            <IconButton
              edge="start"
              color="inherit"
              aria-label="menu"
              onClick={handleDrawerOpen}
              sx={{ display: { md: 'none' } }} // Visible solo en pantallas pequeñas (md y down)
            >
              <MenuIcon />
            </IconButton>
            <Box sx={{ display: { xs: 'none', md: 'flex' } }}>
              {/* Opciones de navegación para pantallas medianas y grandes (md y up) */}
              <IconButton color="inherit" component={RouterLink} to="/" sx={{ '&:hover': { color: '#ff4081' } }}>
                <HomeIcon /><Typography variant="body1">Accueil</Typography>
              </IconButton>
              <IconButton color="inherit" component={RouterLink} to="/soluciones" sx={{ '&:hover': { color: '#ff4081' } }}>
                <SolutionIcon /><Typography variant="body1">Solutions</Typography>
              </IconButton>
              <IconButton color="inherit" component={RouterLink} to="/sobrenosotros" sx={{ '&:hover': { color: '#ff4081' } }}>
                <InfoIcon /><Typography variant="body1">À Propos de Nous</Typography>
              </IconButton>
              <IconButton color="inherit" component={RouterLink} to="/contacto" sx={{ '&:hover': { color: '#ff4081' } }}>
                <ContactMailIcon /><Typography variant="body1">Contact</Typography>
              </IconButton>
              <IconButton
                color="inherit"
                href="https://tracking.navigogps.ca"
                target="_blank"
                rel="noopener noreferrer"
                sx={{ '&:hover': { color: '#ff4081' } }}
              >
                <ExitToAppIcon /><Typography variant="body1">Connexion à la Plateforme</Typography>
              </IconButton>
            </Box>
          </Box>
        </Toolbar>
      </AppBar>
      {/* Menú desplegable */}
      <Drawer anchor="left" open={drawerOpen} onClose={handleDrawerClose} sx={{ '& .MuiDrawer-paper': { width: '200px' } }}>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            p: 2,
            bgcolor: '#2E3B55',
            borderBottom: '1px solid #fff',
          }}
        >
          <img src={logo} alt="Logo" height="60px" width="100px" />
        </Box>
        <Box sx={{ p: 2 }}>
          <IconButton color="inherit" component={RouterLink} to="/" onClick={handleDrawerClose}>
            <HomeIcon /><Typography variant="body1">Accueil</Typography>
          </IconButton>
          <IconButton color="inherit" component={RouterLink} to="/soluciones" onClick={handleDrawerClose}>
            <SolutionIcon /><Typography variant="body1">Solutions</Typography>
          </IconButton>
          <IconButton color="inherit" component={RouterLink} to="/sobrenosotros" onClick={handleDrawerClose}>
            <InfoIcon /><Typography variant="body1">À Propos de Nous</Typography>
          </IconButton>
          <IconButton color="inherit" component={RouterLink} to="/contacto" onClick={handleDrawerClose}>
            <ContactMailIcon /><Typography variant="body1">Contact</Typography>
          </IconButton>
          <IconButton
            color="inherit"
            href="https://tracking.navigogps.ca"
            target="_blank"
            rel="noopener noreferrer"
            onClick={handleDrawerClose}
          >
            <ExitToAppIcon /><Typography variant="body1">Connexion à la Plateforme</Typography>
          </IconButton>
        </Box>
      </Drawer>
    </div>
  );
}

export default Header;
