import React from 'react';
import './AboutUsPage.css'; // Importamos el archivo de estilos CSS

const AboutUsPage = () => {
    return (
        <div className="about-us-container">
            <h2 className="section-title">À Propos de Nous</h2>
            <div className="content">
                <p className="intro-text">
                    Bienvenue chez Navigo, leaders en solutions de suivi par satellite et de technologie GPS.
                </p>
                <p>
                    Chez Navigo, nous sommes fiers de proposer les meilleures solutions de suivi et de localisation, conçues pour répondre aux besoins de nos clients dans tous les secteurs d'activité. Des petites entreprises aux grandes flottes d'entreprises, nos produits et plateformes de suivi sont conçus pour offrir précision, sécurité et efficacité.
                </p>
                <p>
                    Notre équipe d'experts s'engage à fournir un service d'excellence et une attention clientèle inégalée. Forts de nombreuses années d'expérience sur le marché, nous avons établi une réputation solide et fiable dans le domaine de la technologie de suivi par satellite.
                </p>
                <h3>Nos Produits</h3>
                <p>
                    Chez Navigo, nous proposons une large gamme de produits de suivi GPS, y compris des dispositifs de suivi pour les véhicules terrestres, les embarcations maritimes et les équipements spécialisés. Nos dispositifs de dernière génération utilisent une technologie satellitaire de pointe pour fournir une localisation précise et en temps réel.
                </p>
                <h3>Nos Plateformes</h3>
                <p>
                    En plus de nos produits matériels, nous fournissons également des plateformes logicielles avancées pour la surveillance et le suivi de flottes. Nos plateformes faciles à utiliser offrent une large gamme de fonctionnalités, telles que le suivi en temps réel, l'historique des itinéraires, les notifications d'alerte et bien plus encore.
                </p>
                <h3>Notre Engagement</h3>
                <p>
                    Chez Navigo, nous sommes engagés envers la satisfaction du client et la qualité du service. Nous nous efforçons de dépasser les attentes de nos clients à chaque étape du processus et nous sommes dédiés à fournir des solutions de suivi de première classe.
                </p>
                <p className="contact-info">
                    Merci d'avoir choisi Navigo comme partenaire fiable en technologie de suivi par satellite ! Si vous avez des questions ou besoin d'assistance, n'hésitez pas à nous contacter. Nous sommes là pour vous aider.
                </p>
            </div>
        </div>
    );
}

export default AboutUsPage;
