import React from 'react';
import { Button, Box } from '@mui/material';

const MainButtonComponent = ({children}) => {
    return (
        <Box 
          mt={2} 
          display="flex" 
          justifyContent="center"
        >
          <Button 
            variant="contained" 
            color="primary" 
            size="large"
            sx={{ 
              transition: '0.3s',
              '&:hover': { 
                  backgroundColor: '#ff4081', 
                  transform: 'scale(1.05)', 
              } 
            }}
          >
            {children}
          </Button>
        </Box>
    );
}

export default MainButtonComponent;
