import React from 'react';
import './ContactUsPage.css'; // Importamos el archivo de estilos CSS

const ContactUsPage = () => {
    return (
        <div className="contact-container">
            <h2 className="contact-heading">Contactez-nous !</h2>
            <div className="contact-info">
                <p>
                    Si vous souhaitez nous contacter, vous pouvez le faire par les moyens suivants :
                </p>
                <div className="contact-method">
                    <div className="contact-icon">
                        <i className="fas fa-phone-alt"></i>
                    </div>
                    <div className="contact-details">
                        <p className="contact-label">Appelez-nous au :</p>
                        <a href="tel:+1-450-209-7144" className="contact-link">+1-450-209-7144</a>
                    </div>
                </div>
                <div className="contact-method">
                    <div className="contact-icon">
                        <i className="fas fa-envelope"></i>
                    </div>
                    <div className="contact-details">
                        <p className="contact-label">Envoyez-nous un courriel à :</p>
                        <a href="mailto:info@navigogps.ca" className="contact-link">info@navigogps.ca</a>
                    </div>
                </div>
                <div className="contact-method">
                    <div className="contact-icon">
                        <i className="fab fa-whatsapp"></i>
                    </div>
                    <div className="contact-details">
                        <p className="contact-label">Discutez avec nous sur WhatsApp :</p>
                        <a href="https://wa.me/+14502097144" target="_blank" rel="noopener noreferrer" className="contact-link">+1-450-209-7144</a>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default ContactUsPage;
