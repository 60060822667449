import React from 'react';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import slider1 from '../assets/images/slider1.png';
import slider2 from '../assets/images/slider2.png';
import './HomePage.css'; 

const SliderComponent = () => {
    const settings = {
      dots: true,
      infinite: true,
      speed: 350,
      slidesToShow: 1,
      slidesToScroll: 1,
      autoplay: true,
    };
    const images = [slider1, slider2];
    return (
        <Slider {...settings}>
          {images.map((image, index) => (
            <div key={index}>
              <img className="slider-img" src={image} alt={`Slider ${index + 1}`} />
            </div>
          ))}
        </Slider>
    );
}

export default SliderComponent;
